



















































import Cookies from "js-cookie";
import axios from "axios";

export default {
  layout: "login",
  name: 'Login',
  data: () => ({
    buttonLoading: false,
    showSignUp: false,
    formData: {
      shopDomain: ''
    }
  }),
  methods: {
    async signIn() {
      if (this.formData.shopDomain !== '') {
        this.showSignUp = false;
        this.buttonLoading = true;
        await axios.post("/api/setup/check", { shop: `${this.formData.shopDomain}.myshopify.com` })
            .then((response) => {
              const installed = response.data.data.installed;
              if (installed) {
                window.open(`https://magiccart.web.app/setup/authorize?shop=${this.formData.shopDomain}.myshopify.com`, "_self")
              } else {
                this.showSignUp = true;
              }
              this.buttonLoading = false;
            });
      }
    },
  },
  mounted() {
    if (Cookies.get('shop') && Cookies.get('token')) {
      this.$router.push('/');
    }
  }
}
